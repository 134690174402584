import { getFragmentData } from '@/gql';
import { type FragmentType } from '@/gql/fragment-masking';
import { type DocumentTypeDecoration } from '@graphql-typed-document-node/core';
import { isNil } from 'lodash-es';

export function useFragmentData<TType>(
  documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: MaybeRefOrGetter<
    FragmentType<DocumentTypeDecoration<TType, any>>
  >
): Readonly<Ref<TType>>;
export function useFragmentData<TType>(
  documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: MaybeRefOrGetter<FragmentType<
    DocumentTypeDecoration<TType, any>
  > | null>
): Readonly<Ref<TType | null>>;
export function useFragmentData<TType>(
  documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: MaybeRefOrGetter<
    FragmentType<DocumentTypeDecoration<TType, any>> | undefined
  >
): Readonly<Ref<TType | undefined>>;
export function useFragmentData<TType>(
  documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: MaybeRefOrGetter<
    FragmentType<DocumentTypeDecoration<TType, any>> | null | undefined
  >
): Readonly<Ref<TType | null | undefined>>;

export function useFragmentData<TType>(
  documentNode: DocumentTypeDecoration<TType, any>,
  fragmentType: MaybeRefOrGetter<
    FragmentType<DocumentTypeDecoration<TType, any>> | null | undefined
  >
): Readonly<Ref<TType | null | undefined>> {
  return toRef(() => {
    const fragmentTypeValue = toValue(fragmentType);
    if (isNil(fragmentTypeValue)) return fragmentTypeValue;
    return getFragmentData(documentNode, fragmentTypeValue);
  });
}
